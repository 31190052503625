

div.App__background_container {
  width:100vw;
  height: 100%;
  min-height: 100vh;
  background-color:#eee;
}

div.App__title_word_right, div.App__title_word_left{

  font-size: 10vmin;
  text-transform: uppercase;
  font-family: "Frijole", cursive;
  /* Cool trick to make the text slightly overlap the image in some cases */
  line-height: 5vmin;
  /* Needed to balance out the lost spacing from edited line height */
  padding-top: 5vmin;
}
div.App__title_word_left{
  float:left;
}
div.App__title_word_right{
  float:right;
}
div.App__content_container{
  width:80vw;
  max-width: 50em;
}
div.subheadline{
  margin-bottom: 1em;
}
img.App__top_image{
  height: 25vh;
}

.footer{
  padding: 2em 0 2em 0!important;
}

img.image_pixel_art{
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}
