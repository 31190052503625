.ui.card{
  font-size: 1em!important;
  width: 10vmin!important;
  /* height: 15vmin!important; */
}
.ui.card item_description{
  padding: 0 .5em 0 .5em;
}
.ui.card img{
  width:10vmin!important;
  height: auto!important;
  background-color: #888!important;
}
.ui.modal img{
  height: 10vmin!important;
  width: auto!important;
  background-color: #888!important;
}

.ui.modal img.tileset{
  width: 50vw!important;
  height: auto!important;
  background-color: #888!important;
}
