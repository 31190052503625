*{
  /* No Suprises */
  padding: 0;
  margin: 0;
}

body{
  overflow-x: hidden;
  overflow-y: scroll;
}
